  .my-div {
    box-sizing: border-box;
    position: absolute;
    width: calc(100% - 135px + 15px);
    height: calc(100vh - 170px);
    top: 150px;
    left: 90px;
    background-color: transparent;
  }
  .my-pagination {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 26px;
    position: relative;
    width: 100%;
/*     left: calc(50% - 1331px/2 - 0.5px); */
    /* top: 93.8%; */
    bottom: 0%;
    background: #FFFFFF;
   /*  border-top: 1px solid; */
  }

  .box-detail {
    box-sizing: border-box;
    position: absolute;
    width: 975px;
    height: 177px;
    left: 90px;
    top: 100px;
    background: #FFFFFF;
    border-bottom: 1px solid #92A7C0;
    border-radius: 4px;
  }

  .box-detail1 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px;
    gap: 9px;
    position: absolute;
    left: 6.25%;
    right: 26.04%;
    top: 34.47%;
    bottom: 60.84%;
    background: #0E0A2F;
    border-bottom: 1px solid #92A7C0;
    border-radius: 4px;
  }
  
  
